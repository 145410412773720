.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .landing-page img {
    width: 40%;
    object-fit: cover;
    z-index: 0;
    margin-top: 2rem;
    opacity: 0.5
  }
  
  .landing-page__title {
    font-size: 4rem;
    margin-bottom: 20rem;
    text-align: center;
    color:#fff;
    position: absolute;
    opacity: 0;
    text-shadow: 0 0 0.5em #000;
  }
  
  .landing-page__button {
    font-size: 2rem;
    padding: 1rem 2rem;
    border: none;
    background-color: #eec638;
    color: #fff;
    border-radius: 1rem;
    cursor: pointer;
    position: absolute;
    z-index: 2;
  }
  
  .landing-page__button:hover {
    background-color: #d5a804;
  }
  .landing-page__slider {
    position: relative;
    display: inline-block;
    width: 5rem; /* 40px / 16px */
    height: 2.5rem; /* 20px / 16px */
    border-radius: 1.3rem; /* 10px / 16px */
    background-color: #f5c518;
    cursor: pointer;
    transition: background-color 0.2s;
    z-index: 1;
  }
  
  .landing-page__slider::before {
    position: absolute;
    content: "";
    width: 2rem; /* 16px / 16px */
    height: 2rem; /* 16px / 16px */
    border-radius: 50%;
    background-color: #ffffff;
    top: 0.25rem; /* 2px / 16px */
    left: 0.25rem; /* 2px / 16px */
    transition: transform 0.2s;
  }
  
  .landing-page__switch {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
  
  .landing-page__switch input[type="checkbox"] {
    display: none;
  }
  
  .landing-page__switch input[type="checkbox"] + .landing-page__slider {
    margin-bottom: 0.25rem; /* 4px / 16px */
  }
  
  .landing-page__switch input[type="checkbox"]:checked + .landing-page__slider {
    background-color: #c6c6c6;
  }
  
  .landing-page__switch input[type="checkbox"]:checked + .landing-page__slider::before {
    transform: translateX(2.5rem); /* 20px / 16px */
  }

  .landing-page__language {
    font-size: 1rem;
    color: aliceblue;
    padding-top: 0.3em;
}

@media screen and (max-width: 1200px) {
  .landing-page img {
    width: 95%;
    object-fit: cover;
    z-index: 0;
    margin-top: 2rem;
    opacity: 0.5
  }
}


@media screen and (max-width: 878px) {
  .landing-page img {
    width: 95%;
    object-fit: cover;
    z-index: 0;
    margin-top: 2rem;
    opacity: 0.5
  }
}