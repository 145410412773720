html, body {
  scroll-behavior: smooth;
  font-size: 16px;
  position: relative;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
}

body::-webkit-scrollbar-thumb {
  background-color: #4f4d4d;
  border-radius: 1rem;
  border: 0.1rem solid #030303;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.2);
}


/* Menu bar styles */

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-menu ul li {
  margin: 0 2%;
  font-size: 1em;
}

.nav-menu ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  padding: 0.5em 1em;
  transition: color 0.3s ease-out;
}

.nav-menu ul li a.active {
  color: #f5c518;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.25em;
  font-size: 1.2em;
}

.nav-menu ul li a:hover {
  color: #f5c518;
}

@media screen and (max-width: 767px) {
  .nav-menu {
    position: sticky;
    background-color: rgba(33, 33, 33, 0.103);
    padding: 0.2em;
    padding-left: 0.5em;
    border-radius: 0.25em;
  }

  .nav-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .nav-menu ul li {
    margin: 0.5em 1em;
  }

  .nav-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 0.8em;
    padding: 0.8em;
    transition: color 0.3s ease-out;
  }

  .nav-menu ul li a.active {
    color: #f5c518;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.25em;
    font-size: 1.2em;
    padding: 0.3em;
  }

  .nav-menu ul li a:hover {
    color: #f5c518;
  }

}


/* Section styles */
section {
  min-height: 400px;
  max-height: auto;
  display: flex;
  flex-direction: column; /* Set section to display its children in a column */
  justify-content: center;
  align-items: center;
  background-color: #212121;
  color: #fff;
}

section:nth-child(even) {
  background-color: #424242;
}

section h2 {
  font-size: 2.5em;
  margin-top: 0em;
  margin-bottom: 3rem; /* Reduce the margin bottom to make space for the carousel */
  text-align: center; /* Center the header */
  text-shadow: 1em 1em 1em rgba(26, 26, 26, 0.824);
}

section h3 {
  font-size: 2em;
  display: flex;
  flex-direction: column; /* Stack h3 elements in a column */
  align-items: center;
  margin-bottom: 1rem; /* Add a small margin between h3 elements */
  flex-basis: 33.33%;
  text-shadow: 0.5em 0.5em 0.5em rgba(26, 26, 26, 0.824);
}

section h4 {
  font-size: 1em;
  display: flex;
  flex-direction: column; /* Stack h3 elements in a column */
  align-items: left;
  margin-bottom: 1rem; /* Add a small margin between h3 elements */
  flex-basis: 33.33%;
  /* make the font thinner */
  font-weight: 300;
  text-align: left;
  margin-left: 1em;
  
}

section p {
  font-size: 1em;
  max-width: 600px;
  text-align: left; /* Center the paragraph text */
  margin-bottom: 2rem; /* Add margin to make space for the carousel */
  padding: 1em;
  display: flex;
  flex-direction: column; /* Stack p elements in a column */
  align-items: center;
  margin-bottom: 1rem; /* Add a small margin between p elements */
  width: 100%;
}

#about {
  min-height: auto;
  background-color: #212121;
  padding: 1em;
}
#news {
  background-color: #212121;
  color: #fff;
  padding: 3em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: 1em;
}

.news-card {
  background-color: #424242;
  border-radius: 0.8em;
  width: 95%;
  max-width: 800px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  padding: 2em;
  align-items: right;
  text-align: left;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 1s, opacity 1s;
  overflow: hidden;
  max-width: fit-content;
}

.news-card-animated {
  background-color: #424242;
  border-radius: 2em;
  max-width: 75%;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  padding: 1em;
  text-align: left;
  transform: translateX(0);
  opacity: 1;
  overflow: hidden;
  align-items: center;
  /* add text shadow to make the text stand out */
}

.news-card h2 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.news-card h3 {
  font-size: 2em;
  text-shadow: 0.5em 0.5em 0.5em rgba(26, 26, 26, 0.824);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.2em;
}

.news-card p {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 1.5em;
  max-width: 90%;
}

#contacts h2 {
  margin-top: 2.5rem;
}

#wrote-about-us {
  margin-bottom: 1em;
  background-color: #424242;

}

#wrote-about-us h2 {
  margin-top: 0em;
  margin-bottom: 3em; /* Reduce the margin bottom to make space for the carousel */
  text-align: center; /* Center the header */
  text-shadow: 0.5em 0.5em 0.5em rgba(26, 26, 26, 0.824);
}

#contacts {
  background-color: #212121;
}


/* Header styles */

.header-container {
  position: relative;
}

.header-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.App-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(33, 33, 33, 0.946);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  transition: background-color 0.3s ease-out;
  border-radius: 0.5em;
}

.App-header:hover {
  background-color: rgba(33, 33, 33, 1);
}

@media screen and (max-width: 767px) {
  .nav-menu {
    flex-direction: column;
    position: sticky;
  }

  .nav-menu ul li {
    margin: 1em 0;
  }

  .header-container {
    max-width: 100%;
    max-height: 450px;
    overflow: hidden;
    min-height: auto;
    margin-bottom: 0em;
    margin-top: 0em;
  }
  
  .header-container img {
    max-width: 100%;
    height: auto;
    float: right;
    overflow: hidden;
    margin-top: 0em;
  }
  

  section {
    margin-top: 0em;
    margin-bottom: 0em;
    padding: 0em;
    padding-top: 0em;
    padding-bottom: 0em;
  }

  #projects {
    margin-bottom: 0em;
    background-color: #212121;
  }

  section h2 {
    font-size: 2em;
    margin-top: 1em;
    margin-bottom: 1em; /* Reduce the margin bottom to make space for the carousel */
    text-align: center; /* Center the header */
    text-shadow: 10px 10px 10px rgba(26, 26, 26, 0.824);
  }

  #wrote-about-us {
    margin-bottom: 1em;
    background-color: #424242;

  }

  #wrote-about-us h2 {
    font-size: 1.3em;
    margin-top: 2em;
    margin-bottom: 1em; /* Reduce the margin bottom to make space for the carousel */
    text-align: center; /* Center the header */
    text-shadow: 0.5em 0.5em 0.5em rgba(26, 26, 26, 0.824);
  }

  #wrote-about-us h3 {
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 1em; /* Reduce the margin bottom to make space for the carousel */
    text-align: center; /* Center the header */
  }

  #wrote-about-us h4 {
    font-size:0.9em;
    margin-top: 1em;
    margin-bottom: 1em; /* Reduce the margin bottom to make space for the carousel */
    text-align: left; /* Center the header */
    padding-left: 0.5em;
  }
  
  section h3 {
    font-size: 1rem;
    display: flex;
    flex-direction: column; /* Stack h3 elements in a column */
    align-items: center;
    flex-basis: 33.33%;
    margin-bottom: 0em;
    margin-top: 0em;
  }

  section p {
    font-size: 15px;
    max-width: 600px;
    text-align: center; /* Center the paragraph text */
    margin-bottom: 2rem; /* Add margin to make space for the carousel */
    padding: 1em;
    display: flex;
    flex-direction: column; /* Stack p elements in a column */
    align-items: center;
    margin-bottom: 1rem; /* Add a small margin between p elements */
    width: 100%;
  }
  
  .news-card-animated {
    background-color: #424242;
    border-radius: 2em;
    max-width: 90%;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    padding: 1em;
    text-align: left;
    transform: translateX(0);
    opacity: 1;
    overflow: hidden;
    align-items: center;
    /* add text shadow to make the text stand out */
  }
  
  .news-card h2 {
    font-size: 3em;
    margin-bottom: 0.5em;
    justify-content: center;
    text-align: center;
  }
  
  .news-card h3 {
    font-size: 2em;
    text-shadow: 0.5em 0.5em 0.5em rgba(26, 26, 26, 0.824);
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0.2em;
    justify-content: center;
    text-align: center;
  }
  
  .news-card p {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 1.5em;
    max-width: 90%;
  }
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212121;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.facebook {
  display: inline-block;
  margin: 0 0.5rem;
}

.facebook:hover {
  opacity: 0.8;
}

.instagram {
  display: inline-block;
  margin: 0 0.5rem;
}

.instagram:hover {
  opacity: 0.8;
}

.twitter {
  display: inline-block;
  margin: 0 0.5rem;
}

.twitter:hover {
  opacity: 0.8;
}



.full-width-image-container img {
  width: 100%;
  height: auto;
}

.swiper-section {
  width: 100%;
  padding: 20px 0;
}

.swiper-section img {
  width: 100%;
  height: auto;
}

.swiper-section p {
  text-align: center;
  /* Additional styling for description text */
}

.swiper-container {
  width: 100%;
  height: 300px; /* Adjust as needed */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-description-section {
  padding: 20px;
  text-align: center; /* Center align the text */
  background-color: #f5f5f5; /* Light background for the section */
  margin: 20px 0; /* Space above and below the section */
}

.image-description-section .sub-header {
  font-size: 24px; /* Larger font size for sub-header */
  font-weight: bold; /* Make the sub-header bold */
  color: #0d0d0d; /* Dark color for the text */
  margin-bottom: 10px; /* Space between sub-header and description */
}

.image-description-section .description {
  font-size: 16px;
  color: #bababa; /* Slightly lighter color for the description */
  line-height: 1.5; /* Increase line height for better readability */
}
