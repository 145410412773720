form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    margin-bottom: 2rem;
  }
  
  form.submitting {
    opacity: 0.5;
  }
  
  label {
    margin-top: 20px;
    font-size: 1rem;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    background-color: #333;
    color: #fff;
  }
  
  input[type="submit"] {
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    position: relative;
    transition: opacity 0.5s ease-in-out;
  }
  
  input[type="submit"].loading {
    opacity: 0;
    pointer-events: none;
  }
  
  input[type="submit"]:hover {
    background-color: #0062cc;
  }
  
  input[type="submit"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(196, 18, 18, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  input[type="submit"].loading + .loader-container {
    opacity: 1;
  }
  
  .loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    background-color: #f5c518;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .success {
    color: #f5c518;
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
  }
  