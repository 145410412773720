.cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

.src-UserCard-styles-module__card-link--1C9Mt {
  padding-left: 3rem;
  padding-right: 3rem;
}
  
  @media screen and (max-width: 767px) {
    .cards {
      flex-direction: column;
      align-items: center;
    }

    .src-UserCard-styles-module__card-link--1C9Mt {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  